import React, {ChangeEvent, useEffect, useState} from "react";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {IContact} from "../types/Contact";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import {NavLink} from "react-router-dom";
import {IContactsState} from "../types/ContactsState";
import CSelect from "../components/Cselect/CSelect";
import {optionType} from "../components/Cselect/types/optionTypes";
import DataTable from "../components/DataTable/DataTable";
import icoEdit from "../imgs/Icon-Edit.svg";
import icoAddOrder from "../imgs/Icon-add-order.svg";
import icoTelegram from "../imgs/ico-telegram.svg";
import icoPhone from "../imgs/ico-phone.svg";
import icoWhatsapp from "../imgs/ico-whatsapp.svg";
import icoEmail from "../imgs/ico-mail.svg";
import icoInstagram from "../imgs/ico-instagram.svg";
import Modal from "../components/Modal/Modal";
import ContactsDetails from "./parts/ContactDetails";
import {IChannel} from "../types/Channel";


let searchTimeOut: any;

export default function Contacts() {

    const [contacts, setContacts] = useState<Array<IContact>>([]);
    const [selectedStatus, setSelectedStatus] = useState("-");
    const [leadSource, setLeadSource] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [selectedLeadSource, setSelectedLeadSource] = useState("-");
    const [searchText, setSearchText] = useState('');
    const [contactStates, setContactStates] = useState<Array<IContactsState>>([]);
    const [stateOptions, setStateOptions] = useState<Array<optionType>>([]);
    const [error, setError] = useState(false);
    const [showedContact, setShowedContact] = useState(0);

    useEffect(() => {
        axios.get(API + '/contacts/').then((response) => {
            if (response?.data) {
                setContacts(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/ContactsStates/').then((response) => {
            if (response?.data) {
                setContactStates(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/LeadSources/').then((response) => {
            if (response?.data) {
                setLeadSource(response.data);

            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/Nationalities/').then((response) => {
            if (response?.data) {
                setNationalities(response.data);

            }
        }).catch((e) => {
            setError(true);
        });


    }, []);


    useEffect(() => {
        setStateOptions((obj) => {
            obj = [];
            contactStates.forEach((item, index) => {
                let objOption = {code: item.name, id: item.id, name: item.name};
                obj.push(objOption as any);
            })
            return obj;
        })
    }, [contactStates])

    function printChannel(ch: IChannel) {
        if (ch.channel_code === "phone") {
            return <a href={'tel:'+ch.identifier}><img src={icoPhone} alt={'phone'}/></a>;
        }
        if (ch.channel_code === "telegram") {
            return <a href={'https://t.me/'+ch.identifier}><img src={icoTelegram} alt={'telegram'}/></a>;
        }
        if (ch.channel_code === "instagram") {
            return <a href={'instagram://user?username='+ch.identifier}><img src={icoInstagram} alt={'instagram'}/></a>;
        }
        if (ch.channel_code === "email") {
            return <a href={'mailTo:'+ch.identifier}><img src={icoEmail} alt={'email'}/></a>;
        }
        if (ch.channel_code === "watsapp") {
            return <a href={'whatsapp://send?abid='+ch.identifier+'&text=Hello!'}><img src={icoWhatsapp} alt={'whatsapp'}/></a>;
        }
    }

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        let status = isNaN(parseInt(selectedStatus)) ? "" : selectedStatus;
        let leadSource = isNaN(parseInt(selectedLeadSource)) ? "" : selectedLeadSource;

        searchTimeOut = setTimeout(() => {
            axios.post(API + '/SearchContacts/', {
                text: searchText,
                status: status,
                leadSource: leadSource
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response?.data) {
                    setContacts(response.data);
                }
            }).catch((e) => {
                setError(true);
            });
        }, 300);
    }, [searchText, selectedStatus, selectedLeadSource])


    return (
        <> {error ? "ERROR" : ""}
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Contacts</div>
                <div className={st.body_panel_heading_control}>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedLeadSource} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedLeadSource(obj?.target?.value);
                        }} name={'lead_source_id'} isSelect2={true} values={leadSource} placeHolder={'Lead Source'}/>
                    </div>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedStatus} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedStatus(obj?.target?.value);
                        }} name={'state'} isSelect2={true} values={stateOptions} placeHolder={'State'}/>
                    </div>
                    <Input name={'search'} value={searchText} onChange={(evt) => setSearchText(evt.target.value)}
                           placeholder={'search'}/>
                    <NavLink to={'/contactForm/'}><Button color={"blue"} type={"plus"}>Add
                        contact</Button></NavLink>
                </div>
            </div>
            <div className={st.body_panel_body}>
                <Modal isShowed={showedContact > 0} onClose={() => setShowedContact(0)}>
                    <ContactsDetails id={showedContact}/>
                </Modal>
            </div>
            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'FIO',
                        id: 'first_name_',
                        render: (item: any) => {
                            return <>{item.first_name} {item.middle_name} {item.last_name}</>;
                        }
                    },
                    {
                        name: 'State',
                        id: 'contact_state_id_',
                        render: (item: any) => {
                            let status = null;
                            contactStates.map((st) => {
                                if (st.id == item.contact_state_id) {
                                    status = st.name;
                                }
                            })
                            return status;
                        }
                    },
                    {
                        name: 'Lead Source',
                        id: 'lead_source_code_',
                        field: "lead_source_code"
                    },
                    {
                        name: 'Nationality',
                        id: 'nationality_id_',
                        render: (item: any) => {
                            let name = null;
                            nationalities.map((st: any) => {
                                if (st.id == item.nationality_id) {
                                    name = st.name;
                                }
                            })
                            return name;
                        }
                    },
                    {
                        name: 'Birth date',
                        id: 'birth_date_',
                        render: (item: any) => {
                            if (item.birth_date != null) {
                                let d = new Date(Date.parse(item.birth_date));
                                let curDate = new Date();
                                return item.birth_date + ' [' + (curDate.getFullYear() - d.getFullYear()) + ']';
                            } else {
                                return '-';
                            }

                        }

                    },
                    {
                        name: 'Activity',
                        id: 'orders_',
                        render: (item: any) => {
                            return <>
                                <span
                                    className={st.quad + ' ' + (item.active_orders > 0 ? st.quad_blue + ' ' + st.quad_cp : st.quad_gray)}
                                    onClick={() => setShowedContact(item.id)}>{item.active_orders}
                                </span>
                                <span
                                    className={st.quad + ' ' + (item.booked_orders > 0 ? st.quad_green : st.quad_gray)}>{item.booked_orders}</span>
                                {/*<span*/}
                                {/*    className={st.quad + ' ' + (item.failed_orders > 0 ? st.quad_red : st.quad_gray)}>{item.failed_orders}</span>*/}
                            </>;
                        }
                    },
                    {
                        name: 'Communication',
                        id: 'communication_',
                        render: (item: any) => {
                            let channels = JSON.parse(item.communications);
                            let main = [];
                            let over = [];
                            return <div style={{display: 'flex', alignItems:'center'}}>
                                {(channels && channels.map((ch: IChannel) => {
                                    if (ch.is_main) {
                                        return printChannel(ch);
                                    }
                                }))}
                                {(channels && channels.map((ch: IChannel) => {
                                    if (!ch.is_main) {
                                        return printChannel(ch);
                                    }
                                }))}
                            </div>
                        }
                    },

                    {
                        name: 'Control',
                        id: 'control_',
                        render: (item: any) => {
                            let state = contactStates.filter((s) => s.id == item.contact_state_id);
                            return <>
                                <NavLink to={'/contactForm/' + item.id}>
                                    <img src={icoEdit} alt={'edit row'}/>
                                </NavLink>
                                {state[0] && state[0].is_order_allowed ?
                                    <NavLink to={'/orderForm/0/' + item.id}>
                                        <img style={{width: '32px'}} src={icoAddOrder} alt={'add order'}/>
                                    </NavLink> : <></>}

                            </>;
                        }
                    }]}

                rowsPerPage={50}
                data={contacts}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/>     </>
    );
}