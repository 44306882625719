import {useImmer} from "use-immer";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../const/API";
import st from "../../styles/template.module.scss";
import icoEditeSmall from "../../imgs/Icon-edit-small.svg";
import Input from "../Input/Input";
import Loader from "../../pages/parts/Loader";

export default function EditableBlock({val, keyp, callBack}: any) {

    let [showIco, setShowIco] = useImmer(true);
    let [editMode, setEditMode] = useImmer(false);
    let [v, setValue] = useState(val);

    useEffect(() => {
        setValue(val);
    }, [val]);
    const savePrice = () => {
        axios.post(API + '/setVehiclePrice/', {
            value: v,
            key: keyp
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            // setValue(v);
            if (callBack) {
                callBack();
            }
            setEditMode(false);
        }).catch((e) => {
            alert(e);
        });
    }

    return (
        (!editMode) ? (
                <div key={"editable" + keyp + "-editable"} className={st.editPrice}>
                    {val != v ? (
                        <Loader/>
                    ) : val} {((showIco) ? (
                    <img src={icoEditeSmall} onClick={() => {
                        setEditMode(true)
                    }}/>
                ) : '')}
                </div>
            )
            :
            <div key={"editable" + keyp} className={st.editPrice}>
                <Input name={'V' + keyp} key={"inputEditable" + keyp} value={v} compact={true} onChange={(evt) => {
                    setValue(evt.target.value)
                }}/>
                <span className={st.editPrice_gray} onClick={() => {
                    setEditMode(false);
                }}>
                    Cancel
                </span> <span className={st.editPrice_blue} onClick={savePrice}>
                    Save
                </span>
            </div>
    );

}