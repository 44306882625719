import st from "../styles/template.module.scss";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import DataTable from "../components/DataTable/DataTable";
import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useImmer} from "use-immer";
import axios from "axios";

import icoSubMenu from "./../imgs/ico-3tchk.svg";
import icoSuperBoost from "./../imgs/flash.svg";
import icoFeatured from "./../imgs/star.svg";
import icoSearch from "./../imgs/search-normal.svg";
import icoSettings from "./../imgs/list-settings-line.svg";

import icoSEdit from "./../imgs/sub-menu/edit.svg";
import icoSRevoke from "./../imgs/sub-menu/revoke.svg";
import icoSTrash from "./../imgs/sub-menu/trash.svg";
import {API, SITE_URL} from "../const/API";
import Modal from "../components/Modal/Modal";
import EditableBlock from "../components/EditableBlock/EditableBlock";
import CSelect from "../components/Cselect/CSelect";
import Checkbox from "../components/Checkbox/Checkbox";


type boost = {
    super_boosts: number,
    featured: number,
    available_super_boosts: number,
    available_featured: number
} | null;

type priceType = {
    days: string,
    caption: string,
    value: string,
};
axios.defaults.withCredentials = true;


function AutoList() {
    /* Чтобы работала сессия */
    const debug = false;

    const [depositBulk, setDepositBulk] = useImmer(0);
    const [boosts, setBoosts] = useImmer<boost>(null);
    /*Активное подменю*/
    let [activeSubMenu, setActiveSubMenu] = useState(0);
    //драфты
    let [drafts, setDrafts] = useImmer<any>([]);
    /* Перечень цен в колонках и в bulk всплывашке */
    const [userPrices, setUserPrices] = useImmer([]);
    /* массив выбранных строк */
    const [selectedRows, setSelectedRows] = useImmer([]);
    /* Отображать поля для ввода массовой цены ? */
    const [showBulkEdit, setShowBulkEdit] = useImmer(false);
    /* Отображать поля для ввода массового депозита ? */
    const [showDepositEdit, setShowDepositEdit] = useImmer(false);
    /* Перечень всех авто */
    const [data, setVehicles] = useImmer([]);
    /* Перечень колонок по которым осуществляется поиск */
    const [searchColumns, setSearchColumns] = useImmer([]);
    /* Отфильтрованная дата (чтобы основная не трогалась) */
    const [filteredData, setFiltered] = useImmer([]);
    /* Текст по которому ищем ? */
    const [searchText, setSearchText] = useImmer('');
    const [isFilterModal, setIsFilterModal] = useImmer(false);
    const [searchFilter, setSearchFilter] = useImmer({
        "model": "-",
        "brand": "-",
        "body": "-",
        isSuperBoost: false,
        isFeatured: false,
        searchText: "",
        minPrice: '',
        maxPrice: ''
    });
    /* Навигация */
    const nav = useNavigate();

    let searchObj: any = [];
    let searchFields: Array<{ key: string, name: string, value: string, style?: object }>;
    /** USE EFFECT BLOCK **/
    useEffect(() => {
        if (debug) {
            console.log('UEF: START LOAD 71');
        }
        if (localStorage.getItem('user') === '202') {
            loadBoosts();
        }
        loadDrafts();
    }, []);
    useEffect(() => {
        if (debug) {
            console.log('UEF: change drafts 81');

        }
        loadVehicles();
    }, [drafts])

    /** /USE EFFECT BLOCK **/
    const printStatus = (item: any) => {
        if (debug) {
            console.log('print status 88');
        }
        if (item.isDraft === false && item.isDraft != null) {
            if (item.item.published) {
                return <span
                    className={st.btn + ' ' + st.btnLightGreen + ' ' + st.btnNoActive}>Published</span>;
            } else {
                return <span
                    className={st.btn + ' ' + st.btnLightRed + ' ' + st.btnNoActive}>Unpublished</span>;
            }
        } else {
            if (!item.isDraft && typeof drafts[item.id] != "undefined") {
                if (drafts[item.id].moderation_status === 'REJECTED') {
                    return <span
                        className={st.btn + ' ' + st.btnLightRed + ' ' + st.btnNoActive}>Rejected</span>;
                } else {
                    return <span
                        className={st.btn + ' ' + st.btnLightOrange + ' ' + st.btnNoActive}>On moderation</span>;
                }
            } else {
                if (item.isDraft > 0) {
                    if (item.item.moderation_status === 'REJECTED') {
                        return <span
                            className={st.btn + ' ' + st.btnLightRed + ' ' + st.btnNoActive}>Rejected</span>;
                    } else {
                        return <span
                            className={st.btn + ' ' + st.btnLightOrange + ' ' + st.btnNoActive}>On moderation</span>;
                    }
                } else {
                    if (item.item.moderation_status === 'REJECTED') {
                        return <span
                            className={st.btn + ' ' + st.btnLightRed + ' ' + st.btnNoActive}>Rejected</span>;
                    } else {
                        return <span
                            className={st.btn + ' ' + st.btnLightOrange + ' ' + st.btnNoActive}>moderation</span>;
                    }
                }
            }
        }

    }

    const loadBoosts = () => {
        if (debug) {
            console.log('load boosts 132');
        }
        axios.get<boost>(API + '/boosts/').then((boostsResponse) => {
            setBoosts(boostsResponse.data);
        }).catch((e) => {
            if (e.response == undefined || e.response.status === 401) {
                localStorage.removeItem("user");
                nav('/login');
            }
        });
    }

    const setColumns = () => {
        if (debug) {
            console.log('set columns 140');
        }
        if (localStorage.getItem('user') === '202') {
            axios.get(API + '/prices/').then((response) => {
                localStorage.setItem("def_currency_id", response.data.def_currency_id);
                let symbol = localStorage.getItem('def_currency_symbol');
                if (!symbol) {
                    axios.get(API + '/CurrencySymbol/' + response.data.def_currency_id + '/').then((resp) => {
                        localStorage.setItem("def_currency_symbol", resp.data.symbol);
                    });

                }
                document.title = 'Cars - ' + response.data.name;
                let uPrices = JSON.parse(response.data.price_pattern);
                searchFields = [];
                uPrices.forEach((price: priceType) => {
                    searchFields.push({
                        key: price.days,
                        name: price.caption,
                        value: "",
                        style: {width: '70px', textTransform: 'capitalize'}
                    });
                })
                let myCustomSort = (d: any, field: any, sortDirection: boolean) => {
                    return d?.sort(function (a: any, b: any) {
                        let aPrice = 1;
                        let bPrice = 1;
                        if (a?.item?.price_options) {
                            let t = JSON.parse(a.item.price_options).price;
                            t.forEach(function (p: any) {
                                // console.log(p.days, field.key);
                                if (p.days == field.key) {
                                    aPrice = p.value * 1;
                                }
                            })
                        }
                        if (b?.item?.price_options) {
                            let t2 = JSON.parse(b.item.price_options).price;
                            t2.forEach(function (p: any) {
                                if (p.days == field.key) {
                                    bPrice = p.value * 1;
                                }
                            })
                        }
                        bPrice = isNaN(bPrice) ? 1 : bPrice;
                        aPrice = isNaN(aPrice) ? 1 : aPrice;
                        if (sortDirection) {
                            return bPrice > aPrice ? 1 : -1;
                        } else {
                            return bPrice < aPrice ? 1 : -1;
                        }
                        return 0;
                    });
                }
                setUserPrices(uPrices);
                searchObj = [
                    {
                        name: '',
                        id: 'img0',
                        style: {width: '60px'},
                        render: ((item: any) => {
                            return (item.ordGroup.code === 'FEATURED' || item.ordGroup.code === 'SUPER_BOOST') ?
                                (
                                    <div className={st.boostCard}>
                                        {item.img ? <img className={'img60'} src={SITE_URL + item.img}/> : ""}
                                        <div
                                            className={st.boostInListing + ' ' + (item?.boostActive?.active_until ? st.pagRight : "")}>{(item.ordGroup.code === 'SUPER_BOOST') ?
                                            <img src={icoSuperBoost} className={st.shadowIco} alt={'SuperBoost'}/> : ''}
                                            {(item.ordGroup.code === 'FEATURED') ?
                                                <img className={st.shadowIco} src={icoFeatured} alt={'Featured'}/> : ''}
                                            {item?.boostActive?.active_until ? (new Date(item?.boostActive?.active_until).getDate() - new Date().getDate()) + 'd  ' + ' ' : ""}
                                        </div>
                                    </div>) : (<>{item.img ?
                                    <img className={'img60'} src={SITE_URL + item.img}/> : ""}</>);

                        })
                    },
                    {
                        name: 'Model',
                        id: 'name0',
                        field: 'firstColumn'
                    },
                    {
                        "name": searchFields, //тут у нас массив!
                        id: 'prices0',
                        sortable: 'true',
                        customSort: myCustomSort,
                        render: (item: any, field: any) => {
                            if (item?.item?.price_options) {
                                let price = JSON.parse(item.item.price_options).price;
                                let result = "";
                                let days = 0;
                                if (price) {
                                    price.forEach((priceItem: { days: string, value: string }) => {
                                        if (field.key == priceItem.days) {
                                            result = priceItem.value;
                                            days = field.key;
                                        }
                                    })
                                }
                                // if ((item.isDraft !== false || item.isDraft == null)) {
                                //     return <>{result}</>;
                                // } else {
                                return <EditableBlock key={item.item.id + "_" + days + "=_d"}
                                                      keyp={(item.isDraft !== false || item.isDraft == null ? -item.item.id : item.item.id) + "_" + days}
                                                      val={result}
                                                      callBack={loadDrafts}/>;
                                // }
                            } else {
                                return null;
                            }
                        }
                    },
                    {
                        name: 'Deposit',
                        id: 'Deposit0',
                        sortable: true,
                        render: (item: any, field: any) => {
                            return <EditableBlock key={item.item.id + "_deposit"}
                                                  keyp={(item.isDraft !== false || item.isDraft == null ? -item.item.id : item.item.id) + "_deposit"}
                                                  val={item.deposit}
                                                  callBack={loadDrafts}/>;
                        },
                        style: {width: '70px', textTransform: 'capitalize'}
                    },
                    {
                        name: 'Status',
                        id: 'Status',
                        render: (item: any) => {
                            return printStatus(item);
                        }
                    },
                    {
                        name: 'URL',
                        id: 'site',
                        render: (item: any) => {
                            if ((item.isDraft !== false && item.isDraft == null) || !item.item.published) {
                                return <></>;
                            }
                            return <a className={st.textInLine} href={item.url}
                                      target={'_blank'}>{item.url}</a>;
                        }
                    },
                    {
                        name: '',
                        id: 'control',
                        style: {width: '36px', minWidth: '36px'},
                        render: (item: any) => {
                            return SubMenu(item);
                        }
                    }];
                setSearchColumns(searchObj);

            }).catch((e) => console.log(e));
        } else {
            /* Перечень колонок для админа */
            searchObj = [
                {
                    name: 'id',
                    id: 'id0',
                    field: 'id',
                    render: ((item: any) => {
                        return (item.ordGroup.code === 'FEATURED' || item.ordGroup.code === 'SUPER_BOOST') ?
                            (
                                <div className={st.boostCard}>
                                    {item.id}
                                    <div className={st.boostInListing}>{(item.ordGroup.code === 'SUPER_BOOST') ?
                                        <img src={icoSuperBoost} className={st.shadowIco} alt={'SuperBoost'}/> : ''}
                                        {(item.ordGroup.code === 'FEATURED') ?
                                            <img className={st.shadowIco} src={icoFeatured} alt={'Featured'}/> : ''}
                                    </div>
                                </div>) : (item.id);

                    }),
                    style: {width: '36px', minWidth: '36px'},
                },
                {
                    name: 'Model',
                    id: 'name0',
                    field: 'firstColumn'
                },
                {
                    name: 'Counterparty',
                    id: 'third0',
                    field: "thirdColumn"
                },
                {
                    name: 'Deposit',
                    id: 'Deposit0',
                    field: "deposit"
                },
                {
                    name: 'Status',
                    id: 'Status',
                    render: (item: any) => {
                        return printStatus(item);
                    }
                },
                {
                    name: 'URL',
                    id: 'site',
                    render: (item: any) => {
                        if ((item.isDraft !== false && item.isDraft == null) || !item.item.published) {
                            return <></>;
                        }
                        return <a className={st.textInLine} href={item.url}
                                  target={'_blank'}>{item.url}</a>;
                    }
                },
                {
                    name: '',
                    id: 'control',
                    style: {width: '36px', minWidth: '36px'},
                    render: (item: any) => {
                        return SubMenu(item);
                    }
                }];
            setSearchColumns(searchObj);
        }
    }


    const loadVehicles = () => {
        if (debug) {
            console.log('load vehicles 308');
        }
        setColumns();
        axios.get(API + '/vehicles/').then((response) => {
            if (drafts) {
                drafts.forEach((dr: any) => {
                    response.data.forEach((veh: any, idx: any) => {
                        if (dr.vehicle_id == veh.id) {
                            response.data.splice(idx, 1);
                        }
                    })
                })
            }
            setVehicles(response.data);
            setFiltered(response.data);
        }).catch((e) => {
            if (e.response == undefined || e.response.status === 401) {
                localStorage.removeItem("user");
                nav('/login');
            }
        });
    }
    const loadDrafts = () => {
        if (debug) {
            console.log('load drafts 332');
        }
        axios.get(API + '/Drafts/').then((response) => {
            let arr: any = [];
            response.data.forEach((item: any) => {
                if (typeof item.vehicle_id !== null) {
                    arr[item.vehicle_id] = item;
                }
            })
            drafts = arr;
            setDrafts(arr);
        }).catch((e) => {
            if (e.response == undefined || e.response.status === 401) {
                localStorage.removeItem("user");
                nav('/login');
            }
            console.log('error drafts')
        });
    }
    const RevokeModeration = (item: any) => {
            axios.get(API + '/revoke/' + item.id + '/' + (item.isDraft || item.isDraft == null ? '1/' : '')).then((response) => {
                loadDrafts();
            }).catch((e) => {
                if (e.response == undefined || e.response.status === 401) {
                    localStorage.removeItem("user");
                    nav('/login');
                }
            });

        }
    ;

    /* удаляет авто */
    const removeVehicle = function (id: any) {
        axios.get(API + '/DeleteVehicle/' + id + '/').then((response) => {
            setFiltered((draft => {
                return data.filter((item: any) => {
                    return !(item.id + "" === id + "");
                })
            }))
        }).catch((e) => {
            alert(e.response.data.data);
        });
    }
    //ставит буст
    const setBoostToCar = function (id: any, ord_group_id: number) {
        if (debug) {
            console.log('set boost 375');
        }
        axios.get(API + '/BoostSet/' + id + '/' + ord_group_id + '/').then((response) => {
            if (response.status === 200) {
                loadBoosts();
                loadDrafts();
            } else {
                console.log('ERROR 395');
            }
        }).catch((e) => {
            alert(e.response.data.data);
        });
    }


    /* submenu*/
    let SubMenu = (item: any) => {
        return <>
            <div key={item.id * (item.isDraft || item.isDraft === null) ? '_drat_' : '' + '_submenu'} onClick={() => {
                let id = item.id * ((item.isDraft || item.isDraft === null) ? -1 : 1);
                if (activeSubMenu !== id) {
                    activeSubMenu = id;
                } else {
                    activeSubMenu = 0;
                }
                setActiveSubMenu(activeSubMenu);
            }} className={st.cp}
                 style={{position: 'relative'}}>
                <img src={icoSubMenu} alt={'choose action'}/>
                <div
                    className={activeSubMenu === item.id * ((item.isDraft || item.isDraft === null) ? -1 : 1) ? st.subMenu : st.hidden + ' ' + st.subMenu}>
                    <ul>
                        <li><NavLink to={'/autoform/' + ((item.isDraft || item.isDraft === null) ? '-' : '') + item.id}
                                     style={{fontSize: '14px'}}>
                            <img src={icoSEdit} alt={'edit row'}/> Edit</NavLink>
                        </li>
                        {(typeof drafts[item.id] != "undefined" || item.isDraft !== false) ? //при Null тоже драфт...
                            <li onClick={() => RevokeModeration(item)} style={{fontSize: '14px'}}>
                                <img src={icoSRevoke} alt={'edit row'}/> Revoke moderation
                            </li> : //не драф и есть бусты
                            <>{localStorage.getItem('user') === '202' ?
                                (
                                    <>
                                        {item.ordGroup.code !== 'FEATURED' && boosts?.available_featured && boosts?.available_featured > 0 ?
                                            <li style={{fontSize: '14px'}} onClick={() => {
                                                setBoostToCar(item.id, 1);
                                            }}>
                                                <img src={icoFeatured} className={st.cp} alt={'Set Featured'}/> Set
                                                Featured
                                            </li> : ''}

                                        {item.ordGroup.code === 'FEATURED' ?
                                            <li style={{fontSize: '14px'}} onClick={() => {
                                                setBoostToCar(item.id, 0);
                                            }}>
                                                <img src={icoFeatured} className={st.cp}
                                                     alt={'Remove Featured'}/> Remove
                                                Featured
                                            </li> : ''}

                                        {item.ordGroup.code !== 'SUPER_BOOST' && boosts?.available_super_boosts && boosts?.available_super_boosts > 0 ?
                                            <li style={{fontSize: '14px'}} onClick={() => {
                                                setBoostToCar(item.id, 3);
                                            }}>
                                                <img src={icoSuperBoost} className={st.cp} alt={'Set SuperBoost'}/> Set
                                                SuperBoost
                                            </li> : ''}</>
                                ) : ''}</>
                        }


                        {(localStorage.getItem('user') === '101') && <>
                            <li style={{fontSize: '14px'}}>
                                <img src={icoSTrash} className={st.cp} alt={'delete row'}
                                     onClick={() => {
                                         removeVehicle(item.id);
                                     }}/> Delete
                            </li>
                            {item.ordGroup.code === 'FEATURED' ?
                                <li style={{fontSize: '14px'}} onClick={() => {
                                    setBoostToCar(item.id, 0);
                                }}>
                                    <img src={icoFeatured} className={st.cp}
                                         alt={'Remove Featured'}/> Remove
                                    featured
                                </li> : ""}
                            {item.ordGroup.code === 'SUPER_BOOST' ?
                                <li style={{fontSize: '14px'}} onClick={() => {
                                    setBoostToCar(item.id, 0);
                                }}>
                                    <img src={icoSuperBoost} className={st.cp}
                                         alt={'Remove SuperBoost'}/> Remove
                                    SuperBoost
                                </li> : ""}
                        </>
                        }

                    </ul>


                </div>
            </div>
        </>;
    }
    /* устанавливает цену текущего дня */
    const setPriceBulkValue = (days: string, evt: React.ChangeEvent<HTMLInputElement>) => {
        setUserPrices((draft) => {
            draft.forEach((item: priceType) => {
                if (item.days == days) {
                    item.value = evt.target.value;
                }
            })
        });
    }
    /* возвращает цену для указанного дня */
    const getPriceForBulkByDays = (days: string) => {
        let val: any;
        userPrices.forEach((item: priceType) => {
            if (item.days === days) {
                if (item.value) {
                    val = item.value;
                } else {
                    val = "";
                }
            }
        })
        return val;
    }

    const setBulkPrice = () => {
        axios.post(API + '/setBulkPrice/', {
            ids: selectedRows,
            values: userPrices
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            loadVehicles();
            setShowBulkEdit(false);
        }).catch((e) => {
            alert(e);
        });
    }
    const setBulkDeposit = () => {
        axios.post(API + '/setBulkDeposit/', {
            ids: selectedRows,
            value: depositBulk
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            loadVehicles();
            setShowDepositEdit(false);
        }).catch((e) => {
            alert(e.response.data.data);
        });
    }
    const modelForFilter = () => {
        let t: any[] = [], ready: any[] = [];
        filteredData.forEach((item: any) => {
            if (searchFilter.brand == "-" || item.brand_id == searchFilter.brand) {
                if (searchFilter.body == "-" || item.item.category_id == searchFilter.body) {
                    if (ready[item.item.model_id] !== 1) {
                        t.push(
                            {
                                "name": item.model,
                                "code": item.item.model_id,
                                "id": item.item.model_id
                            });
                        ready[item.item.model_id] = 1;
                    }
                }
            }
        });
        return t;
    }
    const bodyForFilter = () => {
        let t: any[] = [], ready: any[] = [];
        filteredData.forEach((item: any) => {
            if (searchFilter.brand == "-" || item.brand_id == searchFilter.brand) {
                if (searchFilter.model == "-" || item.item.model_id == searchFilter.model) {
                    if (ready[item.item.category_id] !== 1) {
                        t.push(
                            {
                                "name": item.body,
                                "code": item.item.category_id,
                                "id": item.item.category_id
                            });
                        ready[item.item.category_id] = 1;
                    }
                }
            }
        });
        return t;
    }
    const brandForFilter = () => {
        let t: any[] = [], ready: any[] = [];
        filteredData.forEach((item: any) => {
            if (searchFilter.model == "-" || item.item.model_id == searchFilter.model) {
                if (ready[item.brand_id] !== 1) {
                    t.push(
                        {
                            "name": item.brand,
                            "code": item.brand_id,
                            "id": item.brand_id
                        });
                    ready[item.brand_id] = 1;
                }
            }
        });
        return t;
    }
    const rowClassName = (row: any) => {
        if (row.ordGroup.code === 'FEATURED' || row.ordGroup.code === 'SUPER_BOOST') {
            return st.bgBlue;
        }
    }
    const FilterListCars = () => {
        setFiltered((draft => {
            return data.filter((item: any) => {
                if (searchFilter.isFeatured || searchFilter.isSuperBoost) {
                    if (item?.ordGroup.code !== 'FEATURED' && item?.ordGroup.code !== 'SUPER_BOOST') {
                        return false;
                    }
                }
                if (searchFilter.isFeatured && !searchFilter.isSuperBoost) {
                    if (item?.ordGroup.code !== 'FEATURED') {
                        return false;
                    }
                }
                if (!searchFilter.isFeatured && searchFilter.isSuperBoost) {
                    if (item?.ordGroup.code !== 'SUPER_BOOST') {
                        return false;
                    }
                }
                let intMinPrice = parseInt(searchFilter.minPrice);
                let intMaxPrice = parseInt(searchFilter.maxPrice);
                let itemMaxPrice = parseInt(item.item.max_price);
                let itemMinPrice = parseInt(item.item.min_price);
                //указанная минимальная цена не должна быть выше максимально в авто
                if (!isNaN(intMinPrice) && intMinPrice >= 0 && !isNaN(itemMaxPrice)) {
                    if (intMinPrice > itemMaxPrice) {
                        return false;
                    }
                    if (itemMinPrice<intMinPrice){
                        return false;
                    }

                }
                //если указан верний предел цены, то надо смотреть обе вечиличины
                if (!isNaN(intMaxPrice) && intMaxPrice >= 0) {

                    //если мин цена авто, больше указанно - выключаем
                    if (!isNaN(itemMinPrice) && intMaxPrice < itemMinPrice) {
                        return false;
                    }

                }

                if (searchFilter.searchText.length > 0) {
                    let contain = false;
                    Object.entries(item).forEach((value) => {
                        if (typeof value[1] === "string") {
                            if ((value[1] as string).toLowerCase().includes(searchFilter.searchText.toLowerCase()) && !contain) {
                                contain = true;
                            }
                        }
                    })
                    if (!contain) {
                        return false;
                    }
                }

                return (searchFilter.brand == "-" || item?.brand_id == searchFilter.brand) &&
                    (searchFilter.body == "-" || item?.item.category_id == searchFilter.body) &&
                    (searchFilter.model == "-" || item?.item.model_id == searchFilter.model);
            })
        }))

    }

    /* Изменяется фильтр... */
    useEffect(() => {
        FilterListCars();
    }, [searchFilter, data]);
    const numFilters = () => {
        let num = 0;
        if (searchFilter.body != '-') {
            num++;
        }
        if (searchFilter.model != '-') {
            num++;
        }
        if (searchFilter.brand != '-') {
            num++;
        }
        if (localStorage.getItem('user') === '101' && searchFilter.isFeatured) {
            num++;
        }
        if (localStorage.getItem('user') === '101' && searchFilter.isSuperBoost) {
            num++;
        }


        if (num > 0) {
            return <span className={st.round}>{num}</span>;
        } else {
            return null;
        }
    }

    return (
        <>
            {/* Окно с информацией о кол-ве выбранных элементов */}
            {userPrices && userPrices.length > 0 ? (
                <div key={'editLine'}
                     className={st.popup + ' ' + (selectedRows.length > 0 ? '' : st.hidden)}>
                    <div>{selectedRows.length} items selected</div>
                    <Button color={'blue'} onClick={() => setShowBulkEdit(true)}>Bulk price edit</Button>
                    <Button color={'blue'} onClick={() => setShowDepositEdit(true)}>Bulk deposit edit</Button>
                    <div className={st.closeButton} onClick={() => setSelectedRows([])}></div>
                </div>) : ""}
            <Modal key={'showDepositEdit'} isShowed={showDepositEdit} withCloseBtn={false}>
                <div className={st.flex + ' ' + st.w100 + ' ' + st.gap8}>
                    <div className={'f24'}>Bulk deposit edit</div>
                    <div key={'ShoBulkEditButton'} className={st.closeButton} onClick={() => {
                        setShowDepositEdit(false)
                    }}></div>
                </div>
                <div className={st.flex + ' ' + st.gap8 + ' ' + st.w100}>
                    <Input name={'bulkDeposit'} value={depositBulk}
                           onChange={(evt) => setDepositBulk(evt.target.value)}/>
                </div>
                <div className={st.flex + ' ' + st.w100 + ' ' + st.flexCol + ' ' + st.gap8}>
                    <Button color={"light-blue"} onClick={() => {
                        setShowDepositEdit(false)
                    }}>Cancel</Button>
                    <Button color={"blue"} onClick={() => {
                        setBulkDeposit();
                    }}>Save changes</Button>
                </div>
            </Modal>
            {/* /Окно с информацией о кол-ве выбранных элементов */}
            {userPrices && userPrices.length > 0 ? (
                <Modal key={'showBulkEdit'} isShowed={showBulkEdit} withCloseBtn={false}>
                    <div key={'ShoBulkEditButtonModal' + Math.random()} className={st.flex + ' ' + st.w100}>
                        <div className={'f24'}>Bulk price edit</div>
                        <div key={'ShoBulkEditButton'} className={st.closeButton} onClick={() => {
                            setShowBulkEdit(false)
                        }}></div>
                    </div>
                    <div className={st.flex + ' ' + st.gap8 + ' ' + st.w100}>
                        {
                            userPrices.map((price: priceType) => {
                                return (
                                    <div className={st.flex + ' ' + st.flexCol + ' ' + st.gap8}>
                                        <div className={'f14'}>{price.caption}</div>
                                        <div><Input name={'price____' + price.days}
                                                    value={getPriceForBulkByDays(price.days)}
                                                    onChange={(value) => setPriceBulkValue(price.days, value)}/></div>
                                    </div>);
                            })
                        }
                    </div>
                    <div className={st.flex + ' ' + st.w100 + ' ' + st.flexCol + ' ' + st.gap8}>
                        <Button color={"light-blue"} onClick={() => {
                            setShowBulkEdit(false)
                        }}>Cancel</Button>
                        <Button color={"blue"} onClick={setBulkPrice}>Save changes</Button>
                    </div>
                </Modal>) : <></>}
            {/* Основная панель листинга */}
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Cars</div>
                {localStorage.getItem('user') === '202' ? (
                    <div className={st.flex + ' ' + st.gap8}>
                        <span
                            className={st.btn + ' ' + st.btnLightBlue + ' ' + (searchFilter.isSuperBoost ? st.btnActive : '')}
                            onClick={(e) => {
                                setSearchFilter((draft) => {
                                    draft.isSuperBoost = !draft.isSuperBoost;
                                    return draft;
                                })
                            }}>
                            <img src={icoSuperBoost}
                                 alt={'SuperBoost'}/> SuperBoost {boosts?.available_super_boosts}/{(boosts?.super_boosts ? boosts?.super_boosts : 0) + (boosts?.available_super_boosts ? boosts?.available_super_boosts : 0)} available</span>
                        <span
                            className={st.btn + ' ' + st.btnLightBlue + ' ' + (searchFilter.isFeatured ? st.btnActive : '')}
                            onClick={(e) => {
                                setSearchFilter((draft) => {
                                    draft.isFeatured = !draft.isFeatured;
                                    return draft;
                                });
                            }}>
                            <img src={icoFeatured}
                                 alt={'Featured'}/> Featured {boosts?.available_featured}/{(boosts?.featured ? boosts?.featured : 0) + (boosts?.available_featured ? boosts?.available_featured : 0)} available</span>
                    </div>) : ""}
                <div
                    className={st.btn + ' ' + st.btnWhite + ' ' + (isFilterModal ? st.btnWhite_active : '')}
                    onClick={() => {
                        setIsFilterModal(!isFilterModal);
                    }
                    }><img src={icoSettings} alt={'search'}/> Filters {numFilters()}
                </div>
                <div className={st.body_panel_heading_control}>
                    <Input ico={'search'} placeholder={'search car'} value={searchFilter.searchText} onChange={(e) => {
                        setSearchFilter((draft) => {
                            draft.searchText = e.target.value;
                        })

                    }}/>
                    <Link to={'/autoform'}><Button color={'blue'} type={'plus'}>Add car</Button></Link>
                </div>

            </div>
            <div key={'showFilterModal'} className={st.body_panel_heading}
                 style={{display: isFilterModal ? 'block' : 'none', borderRadius: 0}}>
                <div className={st.flex + ' ' + st.w100 + ' ' + st.gap8}>
                    <div className={st.formVertical}>
                        <label>Price</label>
                        <div className={st.formHorizontal}>
                            <Input name={'minPrice'} value={searchFilter.minPrice}
                                   placeholder={'min price'}
                                   onChange={(e) => {
                                       setSearchFilter((draft) => {
                                           draft.minPrice = e.target.value;
                                       })
                                   }}/>
                            <Input name={'maxPrice'} value={searchFilter.maxPrice}
                                   placeholder={'max price'}
                                   onChange={(e) => {
                                       setSearchFilter((draft) => {
                                           draft.maxPrice = e.target.value;
                                       })
                                   }}/>
                        </div>
                    </div>
                    <div className={st.formVertical}>
                        <label>Brand</label>
                        <CSelect name={'brandFilter'} values={brandForFilter()} value={searchFilter.brand}
                                 isSelect2={true}
                                 onChange={(e) => {
                                     setSearchFilter((draft) => {
                                         draft.brand = e.target.value;
                                         if (e.target.value === '-') {
                                             draft.model = '-';
                                             draft.body = '-';
                                         }
                                     })
                                 }}/>
                    </div>
                    <div className={st.formVertical}>
                        <label>Model</label>
                        <CSelect name={'modelFilter'} values={modelForFilter()} isSelect2={true}
                                 value={searchFilter.model} isDisabled={searchFilter.brand === '-'}
                                 onChange={(e) => {
                                     setSearchFilter((draft) => {
                                         draft.model = e.target.value;
                                     })
                                 }}/>
                    </div>
                    <div className={st.formVertical}>
                        <label>Body</label>
                        <CSelect name={'bodyFilter'} values={bodyForFilter()} isSelect2={true} value={searchFilter.body}
                                 isDisabled={searchFilter.brand === '-'}
                                 onChange={(e) => {
                                     setSearchFilter((draft) => {
                                         draft.body = e.target.value;
                                     })
                                 }}/>
                    </div>


                    {localStorage.getItem('user') === '101' ? (
                        <div className={st.formVertical}>
                            <div>
                                <Checkbox name={'SuperBoost'} isChecked={searchFilter.isSuperBoost}
                                          onClick={() => {
                                              setSearchFilter((draft) => {
                                                  draft.isSuperBoost = !draft.isSuperBoost;
                                              })
                                          }}/> SuperBoost
                            </div>
                            <div>
                                <Checkbox name={'Featured'} isChecked={searchFilter.isFeatured} onClick={() => {
                                    setSearchFilter((draft) => {
                                        draft.isFeatured = !draft.isFeatured;
                                    })
                                }}/> Featured
                            </div>
                        </div>
                    ) : ""}
                    <div className={st.flex + ' ' + st.flexCol + ' ' + st.gap8}>
                        <label>&nbsp;</label>
                        <Button color={"warring"} size={'btn-md'} onClick={() => {
                            setFiltered((draft) => {
                                return data;
                            });
                            setSearchFilter((draft) => {
                                draft.brand = '-';
                                draft.body = '-';
                                draft.model = '-';
                                draft.minPrice='';
                                draft.maxPrice='';
                            })
                        }}>Clear filter</Button>
                    </div>
                </div>
            </div>
            {/* /Основная панель листинга */}
            {/* Листинг */}
            {data.length >= 1 && searchColumns.length > 0 ? (
                <DataTable
                    columns={
                        searchColumns
                    }
                    isSelectRows={userPrices && userPrices.length > 0}
                    rowsPerPage={50}
                    rowStyle={rowClassName}
                    data={filteredData}
                    numPaginationButtons={5}
                    additionalButtons={true}
                    onSelect={setSelectedRows}
                    classForTable={st.body_panel_body}/>
            ) : (<div className={st.body_panel_body + ' ' + st.form}>
                <div className={st.row}>LOADING</div>
            </div>)}
            {/* / Листинг */}
        </>


    );
}

export default AutoList;