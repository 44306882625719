import st from "../styles/template.module.scss";

import React, {ChangeEvent, useEffect, useState} from "react";
import Input from "../components/Input/Input";

import {IContact} from "../types/Contact";
import axios from "axios";
import {API} from "../const/API";
import CSelect from "../components/Cselect/CSelect";
import {IContactsState} from "../types/ContactsState";
import {optionType} from "../components/Cselect/types/optionTypes";
import Button from "../components/Button/Button";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../components/Modal/Modal";

const initialErrors = {
    id: false,
    first_name: false,
    middle_name: false,
    birth_date: false,
    last_name: false,
    contact_state_id: false,
    lead_source_id: false,
    lead_source_sublist_id: false,
    nationality_id: false,
    nationality_code: false,
    communications: false,
    is_deleted: null,
    active_orders: null,
    booked_orders: null,
    failed_orders: null,
    total_orders: null,
    active_orders_list: null
}
let channel = {
    "is_main": false,
    "channel_id": 0,
    "identifier": "",
    "channel_code": "",
    "representative": null
}


/*
*     id: number;
    first_name?: string | null;
    middle_name?: string | null;
    birth_date?: string | null;
    contact_state_id?: number | null;
    lead_source_id?: number | null;
    lead_source_sublist_id?: number | null;
    nationality_id?: number | null;
    communications?: string | object;*/
export default function ContactForm() {
    const navigate = useNavigate();

    let {contactId} = useParams();

    const [errors, setErrors] = useState(initialErrors);
    const [nationality, setNationality] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [contactStates, setContactStates] = useState<Array<IContactsState>>([]);
    const [stateOptions, setStateOptions] = useState<Array<optionType>>([]);
    const [leadSources, setLeadSources] = useState([]);
    const [communications, setCommunications] = useState([]);
    const [isEdited, setIsEdited] = useState(false);


    useEffect(() => {
        if (contactId !== undefined) {
            axios.get(API + '/contact/' + contactId + '/').then((response) => {
                if (response?.data) {
                    setContact(response.data);
                }
            }).catch((e) => {
                setError(true);
            });
        }
        //национальности
        axios.get(API + '/nationalities/').then((response) => {
            if (response?.data) {
                setNationality(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        //источник лида
        axios.get(API + '/LeadSources/').then((response) => {
            if (response?.data) {
                setLeadSources(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        //каналы связи
        axios.get(API + '/Communications/').then((response) => {
            if (response?.data) {
                setCommunications(response.data);
            }
        }).catch((e) => {
            setError(true);
        });

        //статусы
        axios.get(API + '/ContactsStates/').then((response) => {
            if (response?.data) {
                setContactStates(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
    }, []);
    useEffect(() => {
        setStateOptions((obj) => {
            obj = [];
            contactStates.forEach((item) => {
                let objOption = {code: item.name, id: item.id, name: item.name};
                obj.push(objOption as any);
            })
            return obj;
        })
    }, [contactStates])

    const [contact, setContact] = useState<IContact>({
        birth_date: '', //+
        communications: [],
        contact_state_id: 0, //+
        first_name: '', //+
        id: 0,
        lead_source_id: 0, //+
        lead_source_sublist_id: 0,
        middle_name: '', //+
        last_name: '',
        nationality_id: 0, //+
        nationality_code: ''
    });
    const validateAndSubmit = () => {
        if (!contact.first_name || (contact.first_name && contact.first_name.length < 1)) {
            errors.first_name = true;
            setErrors((draft) => {
                let d = {...initialErrors};
                d.first_name = true;
                return d;
            })
        }

        let hasError = false;
        let check = Object.keys(errors);
        check.forEach((item) => {
            if (errors[item as keyof IContact] && !hasError) {
                hasError = true;
            }
        });
        if (!hasError) {
            //SaveContact
            axios.post(API + '/SaveContact/', {
                ...contact
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                navigate('/contacts');
            }).catch((e) => {
                setErrorMessage(e.response.data.data);
                setError(true);
            });
        }


        // console.log(contact, initialErrors);
    }
    const changeHandler = (obj: Event): void => {
        setIsEdited(true);
        const objTarget: any = obj.target;
        const name = objTarget.name as keyof IContact;
        if (errors.hasOwnProperty(name) && errors as any['name']) {
            setErrors((draft) => {
                let d: any = {...errors};
                d[name] = false;
                return d;
            })
        }
        setContact((obj) => {
            (obj as any)[objTarget.name] = objTarget.value;
            return {...obj};
        });

    }
    return (
        <>
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Contact Form</div>
            </div>
            <div className={st.body_panel_body}>
                <div className={st.form}>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.formVertical}>
                            <label>first name*</label>
                            <Input name={'first_name'} onChange={changeHandler} hasError={errors.first_name}
                                   value={contact.first_name}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>middle name</label>
                            <Input name={'middle_name'} onChange={changeHandler} value={contact.middle_name}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>last name</label>
                            <Input name={'last_name'} onChange={changeHandler} value={contact.last_name}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>birth date</label>
                            <Input name={'birth_date'} type={"date"} onChange={changeHandler}
                                   value={contact.birth_date}/>
                        </div>
                    </div>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.formVertical}>
                            <label>nationality</label>
                            <CSelect value={contact.nationality_id} values={nationality} name={'nationality_id'}
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                         setContact((draft) => {
                                             draft.nationality_id = parseInt(e.target.value);
                                             return JSON.parse(JSON.stringify(draft));
                                         })
                                         setIsEdited(true);
                                     }} isSelect2={true}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>status</label>
                            <CSelect value={contact.contact_state_id}
                                     onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                                         setContact(() => {
                                             let d = {...contact};
                                             d.contact_state_id = parseInt(obj.target.value);
                                             return d;
                                         })
                                         setIsEdited(true);
                                     }} name={'contact_state_id'} isSelect2={true} values={stateOptions}
                                     placeHolder={'select state'}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Lead Source</label>
                            <CSelect value={contact.lead_source_id} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                                setContact(() => {
                                    let d = {...contact};
                                    d.lead_source_id = parseInt(obj.target.value);
                                    return d;
                                })
                                setIsEdited(true);
                            }} name={'lead_source_id'} isSelect2={true} values={leadSources}
                                     placeHolder={'select state'}/>
                        </div>
                    </div>

                    <div className={st.row + ' ' + st.row_compact}>

                        <div className={st.addCard} onClick={() => {
                            setContact((draft) => {
                                let d = JSON.parse(JSON.stringify(draft));
                                if (d.communications == null) {
                                    d.communications = [];
                                }
                                d.communications?.push(JSON.parse(JSON.stringify(channel)));
                                return d;
                            })
                        }}>
                            + add channel
                        </div>
                        {contact?.communications?.map((item, index) => {
                            return (
                                <div
                                    className={st.addCard + ' ' + st.formVertical + ' ' + (item.is_main ? st.mainCard : "")}
                                    key={index} onClick={(e) => {
                                    let div = e.target;
                                    if (div && (div as any).classList.contains(st.addCard)) {
                                        setContact((draft) => {
                                            if (draft.communications) {
                                                draft.communications.map((i, indx) => {
                                                    if (draft.communications) {
                                                        if (index === indx) {
                                                            draft.communications[indx].is_main = true;
                                                        } else {
                                                            draft.communications[indx].is_main = false;
                                                        }
                                                    }
                                                })
                                            }
                                            return JSON.parse(JSON.stringify(draft));
                                        })
                                        setIsEdited(true);
                                    }
                                }}>
                                    <div className={st.row_compact + ' ' + st.w100}><label>communication channel</label>
                                        <CSelect name={'channel_id'} isSelect2={false} values={communications}
                                                 value={item.channel_id}
                                                 onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                                                     setContact((draft) => {
                                                         let channel = communications.filter((item) => (item as any).id == obj.target.value)[0];
                                                         if (draft.communications) {
                                                             draft.communications[index].channel_id = (channel as any).id;
                                                             draft.communications[index].channel_code = (channel as any).code;
                                                         }
                                                         return JSON.parse(JSON.stringify(draft));
                                                     })
                                                     setIsEdited(true);
                                                 }}/>
                                    </div>
                                    <div className={st.row_compact + ' ' + st.w100}><label>communications</label>
                                        <Input name={'identifier'} type={"text"} onChange={(e) => {
                                            setContact((draft) => {
                                                if (draft.communications) {
                                                    draft.communications[index].identifier = e.target.value;
                                                }
                                                return JSON.parse(JSON.stringify(draft));
                                            })
                                        }} disableAutocomplete={true} value={item.identifier}/>
                                    </div>

                                </div>
                            );

                        })}

                    </div>
                </div>
            </div>
            <div className={st.row + ' ' + st.flexRight}>
                <Modal isShowed={error} onClose={setError} withCloseBtn={true}>
                    {errorMessage}</Modal>
                {isEdited ? <Button onClick={validateAndSubmit} type={"blank"} color={"blue"}>Save</Button> :""}
                <Button onClick={()=>navigate('/contacts')} type={"blank"} color={"warring"}>Close</Button>
            </div>
        </>
    );
}