import st from './DataTable.module.scss';
import React, {useEffect, useState} from "react";
import Checkbox from "../Checkbox/Checkbox";
import {useImmer} from "use-immer";
import noActiveIco from "./../../imgs/sorting-not-active.svg";
import activeIco from "./../../imgs/sorting-active.svg";


type DataRow = { id?: any, name?: any | Array<any>, field?: string, render?: any, style?: object, sortable?: boolean, customSort?: any }
type DRow = { id: any, [value: string]: any }
type DataTableTypes = {
    columns?: Array<DataRow> | null;
    data?: Array<DRow> | null;
    classForTable?: string | null;
    classForPagination?: string | null;
    rowsPerPage?: number;
    numPaginationButtons?: number;
    additionalButtons?: boolean;
    isSelectRows?: boolean;
    onSelect?: (value: any) => void;
    rowStyle?: (value: any) => any;
    onRowClick?: (value: any) => any;
    onRowDoubleClick?: (value: any) => any;
}

function DataTable({
                       columns = null,
                       data = null,
                       classForTable = null,
                       classForPagination = null,
                       rowsPerPage = 10,
                       numPaginationButtons = 5,
                       additionalButtons = false,
                       isSelectRows = false,
                       rowStyle,
                       onSelect,
                       onRowClick,
                       onRowDoubleClick,
                   }: DataTableTypes) {

    const consoleError = (errorField: string) => {
        console.log("Obj property " + errorField + " not found in obj Data");
        return '';
    }

    const [_data, setCData] = useImmer<Array<DRow> | null>(null);
    const [sortDirection, setSortDirectionField] = useImmer(false);
    const [sortField, setSortField] = useImmer('');

    useEffect(() => {
        setCData(data);
    }, [data]);

    let numPages = 0;
    let totalPages = 0;
    if (_data?.length) {
        totalPages = _data.length / rowsPerPage;
        if (totalPages > numPaginationButtons) {
            numPages = numPaginationButtons;
        } else {
            numPages = totalPages;
        }
    }
    const getPaginationButtons = (startRow: number) => {

        if (numPages > 0) {
            let content: any = [];
            content.push(
                <div className={st.btn + ' ' + st.btnActive} key={'page_' + startRow}>{startRow + 1}</div>
            );
            for (let i = 0; content.length <= numPages - 1; i++) {

                if ((startRow - i - 1) >= 0) {
                    content.unshift(<div className={st.btn} onClick={() => setPage((startRow - i - 1))}
                                         key={'page' + (startRow - i - 1)}>{(startRow - i)}</div>);
                }

                if ((totalPages + 1) > (startRow + i + 2)) {
                    content.push(<div className={st.btn} onClick={() => setPage((startRow + i + 1))}
                                      key={'page_' + (startRow + i + 1)}>{(startRow + i + 2)}</div>);
                }
            }
            if (additionalButtons && startRow > 0) {
                content.unshift(<div className={st.btn} onClick={() => setPage((startRow - 1))}
                                     key={'page_as_text' + (startRow - 1)}>prev</div>);
            }
            if (additionalButtons && (totalPages + 1) > (startRow + 2)) {
                content.push(<div className={st.btn} onClick={() => setPage((startRow + 1))}
                                  key={'page_as_text' + (startRow + 1)}>next</div>);
            }
            return content;
        } else {
            return [];
        }


    }
    const startSelectedRows: Array<any> = [];
    const [startRow, setPage] = useState(0);
    const [selectedRows, setSelected] = useImmer(startSelectedRows);
    const toggleAll = () => {
        if (selectedRows.length > 0) {
            setSelected([]);
        } else {
            setSelected((draft) => {
                _data?.slice(startRow * rowsPerPage, (startRow * rowsPerPage) + rowsPerPage)?.map((item) => {
                    draft.push(item.id);
                });
            });
        }
    }
    const toggleRow = (rowId: any) => {
        setSelected((draft) => {
            let indexInArray = draft.indexOf(rowId);
            if (indexInArray >= 0) {
                draft.splice(indexInArray, 1);
            } else {
                draft.push(rowId);
            }
        });
    }
    const sortTableByColumn = (item: any, field?: any, customFunction?: any) => {
        setSortDirectionField((draft) => !draft);
        if (typeof item.name == "string") {
            setSortField(item.name);
            setCData((draft) => {
                draft?.sort(function (a, b) {
                    // console.log(a[item.field])
                    let aForSort = parseInt(a[item.field]);
                    let bForSort = parseInt(b[item.field]);
                    aForSort = isNaN(aForSort) ? 1 : aForSort;
                    bForSort = isNaN(bForSort) ? 1 : bForSort;

                    if (sortDirection) {
                        return aForSort > bForSort ? 1 : -1;
                    } else {
                        return aForSort < bForSort ? 1 : -1;
                    }
                });
                return draft;
            })

            // console.log(item.name);
            // _data?.sort((a, b) => parseFloat(a[item.name]) - parseFloat(b[item.name]));
        }
        if (typeof item.name == "object") {
            setSortField(field.name);
            setCData((draft) => {
                return customFunction(draft, field, sortDirection);
            });
        }

    }
    const sortIco = (name = '') => {
        return (
            (name == sortField) ?
                <img src={activeIco} alt={'sort'} style={{
                    position: 'absolute',
                    right: '0',
                    transform: 'rotate(' + (sortDirection ? '180' : '0') + 'deg)'
                }}/> :
                <img src={noActiveIco} alt={'sort'} style={{position: 'absolute', right: '0'}}/>

        )
    }
    useEffect(() => {
        if (onSelect) {
            onSelect(selectedRows);
        }
    }, [selectedRows]);

    return (
        <div className={st.wrapper} key={'_datatable'}>
            {_data && _data.length > 0 && Array.isArray(_data) &&
                <>
                    <div className={(classForTable !== null) ? classForTable : ''}>

                        <table className={st.table}>
                            {(columns !== null) ? (
                                <>
                                    <thead>
                                    <tr>
                                        {isSelectRows ? (
                                            <th key={"CHECKED_ALL_TH"} className={st.checkColumn}>
                                                <Checkbox name={'checkAll'} onChange={toggleAll}
                                                          isChecked={selectedRows.length > 0}/>
                                            </th>
                                        ) : (<></>)}
                                        {columns.map((item, index) => (typeof item.name == "string") ? (
                                                <th key={"TH__" + (typeof item.id === "undefined") ? index : item.id}
                                                    style={item.style ? item.style : {}}
                                                    onClick={() => sortTableByColumn(item)}
                                                    className={item.sortable ? st.cp : ""}>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: item.name}}></span> {item.sortable ? sortIco(item.name) : ""}
                                                </th>) :
                                            (typeof item.name == "object") ? (
                                                item.name.map((field: DataRow, idx: any) => (
                                                    <th key={"TH___" + (typeof item.id === "undefined") ? idx : item.id}
                                                        style={field.style ? field.style : {}}
                                                        onClick={() => sortTableByColumn(item, field, item.customSort)}
                                                        className={item.sortable ? st.cp : ""}>
                                                        <span>{field.name}</span> {item.sortable ? sortIco(field.name) : ""}
                                                    </th>
                                                ))

                                            ) : (<></>)
                                        )}
                                    </tr>
                                    </thead>
                                    {(_data !== null) ?
                                        <tbody>
                                        {_data && _data?.slice(startRow * rowsPerPage, (startRow * rowsPerPage) + rowsPerPage).map((item, indx) => (
                                            <tr key={"TR-" + item.id + indx}
                                                className={rowStyle ? rowStyle(item) : undefined}
                                                onClick={() => onRowClick ? onRowClick(item) : undefined}
                                                onDoubleClick={() => onRowDoubleClick ? onRowDoubleClick(item) : undefined}>
                                                {isSelectRows ? (
                                                    <td key={"TD____" + (typeof item.id === "undefined") ? indx : item.id + "_" + item.id}>
                                                        <Checkbox name={'check'} value={item.id}
                                                                  onChange={() => toggleRow(item.id)}
                                                                  isChecked={selectedRows.indexOf(item.id) >= 0}/>
                                                    </td>
                                                ) : (<></>)}
                                                {columns.map((column, index) => (
                                                    (typeof column.name == "string") ?
                                                        (typeof column.render !== "undefined" || column.field === undefined) ? (
                                                            <td key={"TD_" + (typeof item.id === "undefined") ? index : item.id + "__TD" + "0"}>{column.render(item)}</td>) : (
                                                            <td key={"TD_" + (typeof item.id === "undefined") ? index : item.id + "_TD" + "1"}
                                                                dangerouslySetInnerHTML={{__html: ((item && typeof item[column.field] !== "undefined") ? item[column.field] : consoleError(column.field))}}></td>

                                                        )
                                                        :
                                                        (typeof column.render !== "undefined" || column.field === undefined) ? (
                                                            column.name.map((field: DataRow, idx: any) => (
                                                                <td key={"TD_" + (typeof item.id === "undefined") ? idx : item.id + "___TDD"}>{column.render(item, field)}</td>
                                                            ))
                                                        ) : (
                                                            <td key={"TD_" + item.id + "___TDDD"}
                                                                dangerouslySetInnerHTML={{__html: ((item && typeof item[column.field] !== "undefined") ? item[column.field] : consoleError(column.field))}}></td>

                                                        )

                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                        : ''}
                                </>
                            ) : (
                                <>
                                    {(_data !== null) ?
                                        <tbody>
                                        {_data !== null && _data.map((item, index) => (
                                            <tr key={"TR" + item.id + "+" + index}
                                                onClick={onRowClick ? onRowClick(item) : undefined}
                                                onDoubleClick={() => onRowDoubleClick ? onRowDoubleClick(item) : undefined}>
                                                {isSelectRows ? (
                                                    <td key={"TDDDD" + item.id}>
                                                        <Checkbox value={item.id} name={'check'}
                                                                  onChange={() => toggleRow(item.id)}
                                                                  isChecked={selectedRows.indexOf(item.id) >= 0}/>
                                                    </td>
                                                ) : (<></>)}
                                                <td key={"TDD" + item.id}>{item.id}</td>
                                                <td key={"TDDD" + item.id}>{item.value}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        : ''}
                                </>
                            )}
                        </table>

                    </div>
                    <div
                        className={(st.pagination ? st.pagination : '') + ' ' + (classForPagination != null ? classForPagination : '')}>
                        <div>
                            {startRow * rowsPerPage}-{(startRow * rowsPerPage + rowsPerPage > (_data as any).length) ? _data?.length : startRow * rowsPerPage + rowsPerPage} of
                            over {_data?.length} results
                        </div>
                        <div className={st.btnList}>
                            {getPaginationButtons(startRow)}
                        </div>
                    </div>
                </>
            }
        </div>);

}

export default DataTable;