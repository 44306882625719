import st from "../styles/template.module.scss";
import Logo from "../imgs/Logo.svg";
import Button from "../components/Button/Button";

import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API} from "../const/API";

const logout = () => {
    localStorage.removeItem("user");
    axios.get(API + '/logout/').then((response) => {
        window.location.reload();
    }).catch((e) => {
    });

}

function Main({children}: any) {
    useEffect(() => {
        //получаем данные о контрагенте
        if (localStorage.getItem('user') === '202') {
            axios.get(API + '/prices/').then((response) => {
                // counterParty = response.data;
                document.title = 'Cars - ' + response.data.name;
                // console.log(counterParty);
            }).catch((e) => console.log(e));
        }
    }, []);
    return (
        <>
            <div className={st.header}>
                <NavLink to={'/'}><img className={st.header_logo} src={Logo} alt={'Rent Cars Logo'}/></NavLink>
                <div></div>
                <div className={st.header_block_btn}>
                    {/*<Button type={'home'} onClick={()=><NavLink to={'/'}/>}>Main</Button>*/}
                    {/*<Button type={'search'}>Search</Button>*/}
                    <Button onClick={logout}>Logout</Button>
                </div>
            </div>
            <div className={st.body}>
                <div className={st.body_menu}>
                    {(localStorage.getItem('user') === '303' || localStorage.getItem('user') === '101') ? (
                        <>
                            {/*<NavLink to={'/contacts'}*/}
                            {/*         className={({isActive}) => (isActive ? st.active : '')}>Contacts</NavLink>*/}
                            {/*<NavLink to={'/orders'}*/}
                            {/*         className={({isActive}) => (isActive ? st.active : '')}>Orders</NavLink>*/}
                        </>) : (<></>)}
                    <NavLink to={'/cars'} className={({isActive}) => (isActive ? st.active : '')}>Cars</NavLink>
                    {(localStorage.getItem('user') === '202') ? (
                        <>
                            <NavLink to={'/company'} className={({isActive}) => (isActive ? st.active : '')}>My
                                Company</NavLink>
                            {/*<NavLink to={'/MyOrders'}*/}
                            {/*         className={({isActive}) => (isActive ? st.active : '')}>My Orders</NavLink>*/}
                        </>) : (<></>)}

                </div>
                <div className={st.body_content}>
                    <div className={st.body_panel}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;