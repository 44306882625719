import React, {useEffect} from "react";
import {useImmer} from "use-immer";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {useNavigate} from "react-router-dom";

type OrdGroupType = [{
    "id": number,
    "code": string,
    "lang_code": string,
    "name": string,
    "ord": number
}] | null;
export default function MyCompany() {
    const nav = useNavigate();
    const [company, setCompany] = useImmer({} as any);
    const [isLoading, setIsLoading] = useImmer(true);
    const [cities, setCities] = useImmer([]);
    const [OrdGroups, setOrdGroup] = useImmer<OrdGroupType>(null);
    useEffect(() => {
        axios.get<OrdGroupType>(API + '/OrdGroups/').then((OrdGroupResponse) => {
            setOrdGroup(OrdGroupResponse.data);
        }).catch((e) => {
            if (e.response == undefined || e.response.status === 401) {
                localStorage.removeItem("user");
                nav('/login');
            }
        });
        axios.get(API + '/cities/').then((response) => {
            setCities(response.data);
            axios.get(API + '/counterparties/').then((response) => {
                setCompany(response.data[0]);
                setIsLoading(false);
            }).catch((e) => {
                console.log('error');
            });
        }).catch((e) => {
            console.log('error');
        });

    }, []);
    const getCityName = (id: number) => {
        let cityName = null;
        cities.forEach((city: any) => {
            if (city?.id === id) {
                cityName = city.name;
            }
        })
        return cityName;
    }
    const getOrdGroupName = (id: number) => {
        let groupName = null;
        OrdGroups?.forEach((group: any) => {
            if (group?.id === id) {
                groupName = group.name;
            }
        })
        return groupName;
    }
    return (
        <>

            {isLoading ? 'Loading' : <>
                <div className={st.CompanyBlock + ' ' + st.body_panel_body}
                     style={{borderRadius: '12px'}}>
                    <div className={st.t} style={{padding: '24px'}}>
                        <h2>Contact Information</h2>
                        {company?.name.length > 0 ? <p>{company.name}</p> : ""}
                        {company?.name.length > 0 ? <p><strong>City:</strong> {getCityName(company.city_id)}</p> : ""}
                        <br/>
                        {company?.site_url.length > 0 ?
                            <p><strong>Website:</strong> <a href={company.site_url}
                                                            target={'_blank'}>{company.site_url}</a>
                            </p> : ""}
                        {company?.phones.length > 0 ?
                            <p><strong>Phone:</strong> <a href={'tel:' + company.phones}
                                                          target={'_blank'}>{company.phones}</a>
                            </p> : ""}
                        {company?.telegram.length > 0 ? <p><strong>Telegram:</strong> {company.telegram}</p> : ""}
                        {company?.whatsapp.length > 0 ? <p><strong>Whatsapp:</strong> {company.whatsapp}</p> : ""}
                        {company?.contact.length > 0 ? <p><strong>Contact Person:</strong> {company.contact}</p> : ""}
                        <h2>Pricing Plans</h2>
                        <>{company?.price_pattern ? <ul>
                            {JSON.parse(company.price_pattern).map((item: any) => {
                                return (<li>{item.caption == 'day' ? '1' + item.caption : item.caption}</li>)
                            })}
                        </ul> : ""}</>

                        <h2>Rental Conditions</h2>
                        {company?.def_time_zone.length > 0 ?
                            <p><strong>Time zone:</strong> {company.def_time_zone}</p> : ""}
                        {company?.subscription_level_id > 0 ?
                            <p><strong>Subscription level:</strong> {getOrdGroupName(company.subscription_level_id)}
                            </p> : ""}
                    </div>
                </div>
            </>}

        </>
    );
}